<template>
  <div class="home">
    <div class="header_top">
      <handleback></handleback>
      <div>逐梦前行</div>
      <div
        @click="
          handleilltate();
          playAudio();
        "
      >
        玩法说明
      </div>
    </div>
    <div class="header center" style="align-items: end">
      <div
        class="header_left column"
        @click="
          getderamlist();
          playAudio();
        "
      >
        <div v-if="targetornaments == null" class="def center">
          <i class="el-icon-circle-plus-outline"></i>
        </div>
        <div v-else class="header_left_else">
          <img :src="targetornaments.imageUrl|fullPath" alt width="100%" />
        </div>
        选择心仪饰品
      </div>

      <div class="header_main center">
        <div class="header_circle">
          <el-progress
            type="circle"
            stroke-linecap="butt"
            color="#FF00FF"
            define-back-color="#5C5C5C"
            :percentage="value3"
          ></el-progress>
        </div>
        <div
          class="header_center center"
          :style="`${
            onOpen
              ? `transform: rotate(${successRateTow}deg);transition: transform 3s ease-in-out;`
              : ''
          }`"
        ></div>
        <div class="header_center_c1 center"></div>
        <div class="header_center_c2">
          {{ value3 }}%
          <p class="tip">追梦成功率</p>
        </div>
      </div>
      <div class="header_right column">
        <img src="@/assets/luckweapon.png" alt />
        随机得到饰品
      </div>
    </div>
    <div class="main">
      <el-slider
        v-model="value3"
        :show-tooltip="false"
        :min="5"
        :max="75"
        @input="handlechange"
      >
        <div>{{ value3 }}</div>
      </el-slider>
      <div class="main_top space_around" @click="playAudio()">
        <div
          class="main_top_btn center"
          :class="value3 >= 5 && value3 < 15 ? 'active' : ''"
          @click="handleodds(5)"
        >
          5%
        </div>
        <div
          class="main_top_btn center"
          :class="value3 >= 15 && value3 < 25 ? 'active' : ''"
          @click="handleodds(15)"
        >
          15%
        </div>
        <div
          class="main_top_btn center"
          :class="value3 >= 25 && value3 < 50 ? 'active' : ''"
          @click="handleodds(25)"
        >
          25%
        </div>
        <div
          class="main_top_btn center"
          :class="value3 >= 50 && value3 < 75 ? 'active' : ''"
          @click="handleodds(50)"
        >
          50%
        </div>
        <div
          class="main_top_btn center"
          :class="value3 >= 75 ? 'active' : ''"
          @click="handleodds(75)"
        >
          75%
        </div>
      </div>
      <div
        class="main_center center btn_click"
        v-if="targetornaments == null"
        @click="
          getderamlist();
          playAudio();
        "
      >
        <div>请选择饰品</div>
      </div>
      <div
        v-else
        class="main_center center btn_click"
        @click="
          handlederamupgrade();
          playAudio();
        "
      >
        <div class="center">
          花费 &nbsp;
          <span class="money-money">
            <money class="main_money"></money>
            {{ luckeyMoney }}
          </span>
          &nbsp; 试试运气
        </div>
      </div>
    </div>
    <!-- 选择饰品 -->
    <div class="out" v-if="onselect" @click.stop="isqulity = false">
      <div class="animate__animated animate__slideInUp" ref="selectbox">
        <div class="out_title space_between">
          <div>价格区间</div>
          <div
            class="close"
            @click="
              handleCloseSelect();
              playAudio();
            "
          >
            <svg
              t="1706948091846"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3533"
              width="100%"
              height="100%"
            >
              <path
                d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
                fill="#727477"
                p-id="3534"
              />
            </svg>
          </div>
        </div>
        <div class="out_top space_between">
          <div class="price_input center">
            <input
              type="text"
              v-model="queryParams.priceMin"
              placeholder="最小值"
            />
            <div
              style="
                border-top: 1px solid #fff;
                width: 14px;
                margin-left: 2px;
                margin-right: 3px;
              "
            ></div>
            <input
              type="text"
              v-model="queryParams.priceMax"
              placeholder="最大值"
            />
          </div>
          <div
            class="price_btn center btn_click"
            @click="
              handleserch();
              playAudio();
            "
          >
            筛选
          </div>
        </div>
        <div class="out_center space_between">
          <div
            class="out_type center"
            @click.stop="
              handleOnSelectType();
              playAudio();
            "
          >
            {{ weaponetype }}
            <i
              class="el-icon-arrow-down"
              style="margin-left: 5px; font-weight: bold"
            ></i>
            <div v-if="isqulity" class="out_type_select">
              <div
                class="out_type_select_btn center"
                :class="queryParams.type == '' ? 'active' : ''"
                @click.stop="
                  handleQuality(0);
                  playAudio();
                "
              >
                默认
              </div>
              <div
                class="out_type_select_btn center"
                v-for="(item, index) in qualityList"
                :key="index"
                :class="
                  queryParams.type == Object.values(item)[0] ? 'active' : ''
                "
                @click.stop="
                  handleQuality(item);
                  playAudio();
                "
              >
                <!-- <div
                class="out_type_select_btn center"
                v-for="(item,index) in qualityList"
                :key="index"
                :class="queryParams.type == Object.values(item)[0] ? 'active' : ''"
                @click.stop="handleQuality(Number(Object.values(item)[0]))"
              > -->
                <div class="xl_btn">{{ Object.keys(item)[0] }}</div>
              </div>
            </div>
          </div>
          <div class="out_center_right">
            <input
              type="text"
              v-model="queryParams.itemName"
              name
              id
              style="serch"
              placeholder="请输入饰品名称"
            />
            <svg
              @click="handleserch"
              t="1705982070098"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="3285"
              width="30"
              height="30"
            >
              <path
                d="M512.034281 76.882018c-240.202711 0-435.165054 194.83136-435.165054 435.124122 0 240.268202 194.96132 435.100586 435.165054 435.100586 59.585073 0 116.371404-12.05557 168.096463-33.743522-13.106506-5.012154-24.790616-14.037714-32.602535-26.979468L544.87013 714.613604c-111.893416 33.626865-236.425687-10.772344-299.55447-115.499547-74.789357-123.859959-34.93465-285.041942 88.921215-359.814925 124.066667-74.738192 285.119713-34.814923 359.861998 89.102341 63.10627 104.58701 44.26311 235.499595-37.545104 318.843792l102.704127 171.745573c8.442276 13.96813 10.727319 29.801746 8.185426 44.703131 108.698654-79.121012 179.662893-206.981075 179.662893-351.687828C947.106214 271.713378 752.261551 76.882018 512.034281 76.882018"
                fill="#3A97E4"
                p-id="3286"
              />
              <path
                d="M385.72862 324.729849c-76.770478 46.324048-101.538581 146.152684-55.153134 223.013213 46.385447 76.905554 146.222269 101.531418 223.180012 55.149041 76.842109-46.310745 101.538581-146.233526 55.153134-223.01219C562.546721 302.902728 462.687386 278.230816 385.72862 324.729849"
                fill="#3A97E4"
                p-id="3287"
              />
            </svg>
          </div>
        </div>
        <div
          class="out_bottom"
          v-loading="onselectweapon"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div v-if="omlist.length == 0">暂无饰品</div>
          <div class="grade3" v-else>
            <div
              @click="
                handleSelect(item);
                playAudio();
              "
              v-for="(item, index) in omlist"
              :key="index"
              class="out_bottom_weapone column"
              :style="{ 'background-image': `url(${item.levelImg})` }"
            >
              <div class="out_bottom_img">
                <img :src="item.imageUrl|fullPath" alt width="100%" />
              </div>
              <div class="textover-f">{{ item.shortName }}</div>
              <div class="textover-f">{{ item.exteriorName }}</div>
              <div class="money-money">
                <money class="money"></money>
                {{ item.usePrice }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="fall column" v-if="isResult">
      <div
        id="fall_weapon"
        ref="myElement"
        class="fall_weapon animate__animated animate__fadeInDown column"
      >
        <div v-if="success">
          <div class="fall_name">恭喜获得</div>
          <div class="fall_img">
            <!-- <div class="textover-f">{{ resultornaments.ornamentName }}</div> -->

            <div
              class="img_box"
              :style="{
                'background-image': `url(${resultornaments.ornamentLevelImg})`,
              }"
            >
              <img :src="resultornaments.ornamentImgUrl|fullPath" alt width="100%" />
            </div>
            <div class="textover-f">{{ resultornaments.ornamentName }}</div>

            <!-- <div class="textover-f">{{ resultornaments.shortName }}</div> -->
            <div class="money-money" style="margin-top: 7px">
              <money class="money"></money>
              {{ resultornaments.ornamentPrice }}
            </div>
          </div>
        </div>
        <div v-else class="fail">
          <div>{{ resultornaments }}</div>
          <img src="fail.png" alt="" />
        </div>
        <div @click="handleClose" class="fall_btn center btn_click">确定</div>
      </div>
    </div>
    <div class="type space_around">
      <div
        @click="
          handletype('02');
          playAudio();
        "
        class="type_btn center"
        :class="'active'"
      >
        历史掉落
      </div>
      <!-- <div
        @click="handletype('01');playAudio()"
        class="type_btn center"
        :class="recordParams.userType == '01' ? 'active' : ''"
      >
        主播掉落
      </div> -->
    </div>
    <div>
      <div class="table_box">
        <table
          class="table"
          cellspacing="0"
          cellpadding="1"
          border="none"
          width="100%"
        >
          <tr class="table_header">
            <th>昵称</th>
            <th>获得</th>
            <th>价值</th>
            <th>成功率</th>
            <th>结果</th>
            <th>时间</th>
          </tr>
          <tr class="table_body" v-for="item in recordlist" :key="item.id">
            <th>{{ item.nickName }}</th>
            <th
              class="img"
              :style="{
                'background': `url(${
                  JSON.parse(item.gainOrnamentList)[0].ornamentLevelImg
                }) center  no-repeat`,
                'background-size': '100% 70%',
              }"
              v-if="item.gainOrnamentList"
            >
              <img
                :src="JSON.parse(item.gainOrnamentList)[0].ornamentImgUrl|fullPath"
                alt
                width="100%"
              />
            </th>
            <th v-else class="img">
              <img src="" alt width="100%" />
            </th>
            <th>{{ item.gainOrnamentsPrice }}</th>
            <th>{{ item.probability + "%" }}</th>
            <th>
              <div v-if="item.isVictory" style="color: rgba(37, 244, 132, 1)">
                成功
              </div>
              <div v-else>失败</div>
            </th>
            <th>{{ item.openTime }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  deramlist,
  deramrecord,
  deramupgrade,
  shopcondition,
  getUserInfo,
} from "@/api/index";
export default {
  data() {
    return {
      success: true,
      onselectweapon: false,
      isqulity: false,
      weaponetype: "类别",
      successRateTow: 3600,
      onOpen: false,
      isResult: false,
      targetornaments: null,
      resultornaments: null,
      onselect: false,
      value3: Number(25),
      pkAudio: new Audio(require("../../assets/1.mp3")),
      omlist: [],
      recordlist: [],
      qualityList: [],
      queryParams: {
        itemName: "",
        priceMax: null,
        priceMin: null,
      },
      recordParams: {
        size: 10,
        page: 1,
      },
      isOver: "加载中",
      timer: null,
    };
  },
  computed: {
    luckeyMoney() {
      let bean = 0;
      // console.log('targetornaments.usePrice',this.targetornaments.usePrice);
      // console.log('value3',this.value3);
      bean = (this.targetornaments.usePrice * this.value3) / 100;
      return bean.toFixed(2);
    },
  },
  mounted() {
    let aa = document.getElementsByClassName("el-slider__button")[0];
    aa.innerText = this.value3;
    window.addEventListener("popstate", () => {
      if (this.onselect) {
        // console.log(1);
        this.onselect = false;
      }
    });
    this.timer = setInterval(() => {
      if (this.targetornaments) {
        this.getderamrecord();
      }
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    onselect(newValue) {
      if (newValue) {
        const state = { title: "title", url: "#" };
        window.history.pushState(state, state.title, state.url);
      }
    },
  },
  methods: {
    playDreamAudio() {
      this.pkAudio.play();
    },
    playAudio() {
      this.$store.commit("playAudio");
    },
    scrollEventFn(e) {
      // console.log(e);
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        // console.log(11);

        if (this.isOver == "加载中") {
          this.recordParams.page++;
          setTimeout(() => {
            this.getderamrecord();
          }, 5);
        }
      }
    },
    handleilltate() {
      // const data = {};
      this.$bus.$emit("is_illustrate", true);
      setTimeout(() => {
        this.$bus.$emit("nr_illustrate", "dream");
      }, 100);
    },
    handleQuality(item) {
      // console.log("12345", item);
      let res = Number(Object.values(item)[0]);
      // console.log(Object.keys(item));
      this.weaponetype = Object.keys(item)[0];
      if (item == 0) {
        this.weaponetype = "类别";

        this.queryParams = {};
        this.handleserch();
      } else {
        this.queryParams.type = res;
        this.handleserch();
      }
      this.isqulity = false;
    },
    handleOnSelectType() {
      this.isqulity = true;
      shopcondition(0).then((res) => {
        this.qualityList = res.data.data;
      });
    },
    handleCloseSelect() {
      this.weaponetype = "类别";
      const element = this.$refs.selectbox; // 获取元素对象
      element.className += " animate__slideOutDown";
      setTimeout(() => {
        this.onselect = false;
        this.$router.back();
      }, 500);
    },
    handleClose() {
      // animate__fadeOutDown
      const element = this.$refs.myElement; // 获取元素对象
      element.className += " animate__fadeOutDown";
      // element.classList.add("animate__fadeOutDown");
      setTimeout(() => {
        this.isResult = false;
      }, 500);
    },
    handleSelect(res) {
      this.handleCloseSelect();
      this.targetornaments = res;
      this.recordParams.ornamentId = res.ornamentId;
      this.getderamrecord();
    },
    handletype() {
      this.recordParams.page = 1;
      this.recordlist = [];
      this.getderamrecord();
    },
    handleserch() {
      // console.log(1);
      this.onselectweapon = true;
      deramlist(this.queryParams).then((res) => {
        this.omlist = res.data.data;
        setTimeout(() => {
          this.onselectweapon = false;
        }, 350);
      });
    },

    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 0.7) + min + 0.2);
    },
    handlederamupgrade() {
      // console.log(this.$store.state.USER_INFO);
      if (
        this.$store.state.USER_INFO.accountAmount +
          this.$store.state.USER_INFO.accountCredits <
        this.luckeyMoney
      ) {
        this.$message({
          message: "余额不足，请充值",
          type: "warning",
          customClass: "log_warning",
        });
        return false;
      } else if (this.$store.state.USER_INFO.isRealCheck == "0") {
        this.$message({
          message: "请先进行实名认证",
          type: "warning",
          customClass: "log_warning",
        });
        return false;
      }

      // console.log("targetornaments", this.targetornaments);
      // this.resultornaments = null;
      let obj = {
        upgradeOrnamentId: this.targetornaments.id,
        price: this.luckeyMoney,
        probability: this.value3,
      };
      // console.log("obj", obj);
      deramupgrade(obj).then((res) => {
        if (res.data.code == 500) {
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "log_warning",
          });
          return false;
        }
        this.onOpen = true;
        clearInterval(this.timer);
        getUserInfo().then((res) => {
          this.user = res.data.data;
          this.$store.commit("USER_INFO", res.data.data);
        });
        if (res.data.data.gainOrnamentList) {
          this.playDreamAudio();
          this.resultornaments = JSON.parse(res.data.data.gainOrnamentList)[0];
          console.log("deramupgrade", this.resultornaments);
          console.log(this.targetornaments.usePrice, this.resultornaments);
          if (
            this.targetornaments.usePrice == this.resultornaments.ornamentPrice
          ) {
            // console.log(1);
            this.successRateTow =
              360 * 15 +
              360 * (this.value3 / 100) -
              Math.floor(Math.random() * (360 * (this.value3 / 100)));
          } else {
            this.successRateTow =
              360 * 15 +
              360 * (this.value3 / 100) +
              this.getRandomNumber(360 * (this.value3 / 100) + 1, 360) -
              360 * (this.value3 / 100);
          }
          setTimeout(() => {
            this.onOpen = false;
            this.isResult = true;
            this.success = true;
            this.recordParams.page = 1;
            this.recordParams.size = 10;
            this.getderamrecord();
            this.timer = setInterval(() => {
              if (this.targetornaments) {
                this.getderamrecord();
              }
            }, 5000);
          }, 3500);
        } else {
          this.playDreamAudio();
          this.resultornaments = "失败啦，啥都没得到~";
          this.successRateTow =
            360 * 15 +
            360 * (this.value3 / 100) +
            this.getRandomNumber(360 * (this.value3 / 100) + 1, 360) -
            360 * (this.value3 / 100);
          setTimeout(() => {
            this.onOpen = false;
            this.isResult = true;
            this.success = false;
            this.recordParams.page = 1;
            this.recordParams.size = 10;
            this.getderamrecord();
            this.timer = setInterval(() => {
              if (this.targetornaments) {
                this.getderamrecord();
              }
            }, 5000);
          }, 3500);
        }
      });
    },

    getderamrecord() {
      if (this.targetornaments) {
        // console.log("getderamrecord", this.targetornaments);
        deramrecord(this.recordParams).then((res) => {
          console.log("deramrecord", res.data.data.records);
          if (res.data.code == 200) {
            // console.log("成功");
            // console.log('rows',res.data.rows);
            this.recordlist = res.data.data.records;
            // console.log('recordlist',this.recordlist);
          }
          if (res.data.data.records.length < this.recordParams.size) {
            this.isOver = "暂无更多";
          }
        });
      } else {
        this.$message({
          message: "请先选择饰品",
          type: "warning",
          customClass: "log_warning",
        });
      }
    },

    getderamlist() {
      // this.onOpen = true;

      // this.successRateTow = 360 * 15;
      // setTimeout(() => {
      //   this.successRateTow;
      //   this.onOpen = false;
      // }, 5000);
      this.onselect = true;
      deramlist().then((res) => {
        this.omlist = res.data.data;
        console.log("omlist", this.omlist);
      });
    },
    handlechange() {
      let aa = document.getElementsByClassName("el-slider__button")[0];
      aa.innerText = this.value3;
    },
    handleodds(res) {
      this.value3 = Number(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.type {
  width: 92px;
  height: 42px;
  margin: 0 auto;
  margin-top: 14px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  @media (max-width: 550px) {
    height: 30px;
    width: 70px;
  }
  .type_btn {
    width: 100%;
    height: 35px;
    cursor: pointer;

    opacity: 1;
    transition: all 0.3s linear;
    color: rgba(114, 116, 119, 1);

    @media (max-width: 550px) {
      height: 25px;
      font-size: 10px;
    }
  }
  .active {
    background: linear-gradient(-270deg, #25f484 0%, #3a97e4 100%);
    border-radius: 70px 70px 70px 70px;
    color: rgba(255, 255, 255, 1);
  }
}

.tooltip-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #409eff;
  color: #fff;
  border-radius: 50%;
}
@keyframes my {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep .el-slider__button-wrapper {
  z-index: 2;
}
::v-deep .el-slider__runway {
  background: rgba(50, 52, 54, 1);
}
::v-deep .el-slider__bar {
  background: rgba(50, 52, 54, 1);
}
::v-deep .el-slider__button {
  background: #25f484;
  // 拖动的滑块的样式
  width: 100%;
  height: auto;
  // border: 1px solid var(--el-color-primary);
  border-radius: 5px;
  border: none;
  // position: relative;
  // left: 4px;
}

.home {
  width: 95%;
  margin: 0 auto;
}
.header_top {
  width: 100%;
  height: 56px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:first-child {
  }
  div:last-child {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    line-height: 1.5;
  }
}
.header {
  width: 100%;
  .header_left {
    width: 84px;
    border-bottom: 3px solid #25f484;
    font-size: 14px;
    cursor: pointer;
    @media (max-width: 550px) {
      font-size: 10px;
      width: 60px;
    }
    .def {
      width: 60px;
      height: 60px;
      font-size: 21px;
      color: rgba(114, 116, 119, 1);
    }
    .header_left_else {
      height: 65px;
      @media (max-width: 550px) {
        font-size: 10px;
        width: 60px;
        height: 52px;
      }
    }
  }
  .header_right {
    font-size: 14px;

    width: 84px;
    border-bottom: 3px solid #3a97e4;
    cursor: pointer;
    @media (max-width: 550px) {
      font-size: 10px;
      width: 60px;
      // height: 52px;
    }
  }
}
.header_main {
  position: relative;
  width: 280px;
  height: 280px;
  @media (max-width: 550px) {
    width: 200px;
    height: 200px;
  }
  .header_circle ::v-deep {
    position: absolute;
    width: 270px;
    height: 270px;
    @media (max-width: 550px) {
      width: 195px;
      height: 195px;
    }
    .el-progress-bar__outer {
      background-color: rgba(0, 0, 0, 0.15);
    }
    .el-progress {
      width: 100%;
      height: 100%;
      .el-progress-circle {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .header_center {
    width: 280px;
    height: 280px;
    box-shadow: 0px 0 14px 0px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border: 1px solid #323436;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    @media (max-width: 550px) {
      width: 200px;
      height: 200px;
    }
  }
  .header_center_c1 {
    width: 255px;
    height: 255px;
    background: #262729;
    opacity: 1;
    border-radius: 50%;
    position: absolute;

    // animation: my 6s infinite linear;
    @media (max-width: 550px) {
      width: 182px;
      height: 182px;
    }
  }
  .header_center_c2 {
    position: absolute;

    width: 214px;
    height: 214px;
    background: #323436;
    box-shadow: 0px 0 14px 0px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 42px;
    @media (max-width: 550px) {
      width: 153px;
      height: 153px;

      font-size: 30px;
    }

    .tip {
      font-size: 14px;
      color: #727477;
      @media (max-width: 550px) {
        font-size: 10px;
      }
    }
  }
  .header_center::before {
    content: ""; /* 必需属性，表示生成内容为空 */
    position: absolute; /* 设置绝对定位 */
    top: 0; /* 调整上边距，根据需要进行微调 */
    left: 50%; /* 将左边界居中 */
    transform: translateX(-50%); /* 水平居中 */
    border-width: 14px; /* 控制三角形大小 */
    border-style: solid; /* 设置边框样式为实线 */
    border-color: rgba(37, 244, 132, 1) transparent transparent transparent; /* 设置边框颜色为绿色 */
    @media (max-width: 550px) {
      border-width: 11px; /* 控制三角形大小 */
    }
  }
}
.main {
  width: 90%;
  margin: 0 auto;

  .main_top {
    height: 42px;
    background: #323436;
    border-radius: 70px;
    opacity: 1;

    font-weight: 700;
    cursor: pointer;
    @media (max-width: 550px) {
      height: 30px;

      font-size: 13px;
    }
    .main_top_btn {
      width: 84px;
      height: 35px;
      color: rgba(114, 116, 119, 1);

      @media (max-width: 550px) {
        height: 25px;
      }
    }
    .active {
      background: linear-gradient(-270deg, #25f484 0%, #3a97e4 100%);
      border-radius: 70px 70px 70px 70px;
      color: #fff;
      font-weight: bold;
      transition: all 0.3s ease;
      @media (max-width: 550px) {
        height: 25px;
      }
    }
  }
  .main_center {
    margin-top: 5%;
    height: 56px;
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(37, 244, 132, 0.1) 100%
    );
    opacity: 0.8;
    border: 1px solid #25f484;
    font-size: 25px;
    transition: all 0.3s ease;
    cursor: pointer;
    @media (max-width: 550px) {
      height: 40px;

      font-size: 18px;
    }
    &:hover {
      opacity: 1;
    }
    .main_money {
      width: 25px;
      height: 25px;
    }
  }
}
.out {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  text-align: left;
  @media (max-width: 550px) {
    width: 100%;
  }
  .out_title {
    padding: 20px 20px 0 20px;
    @media (max-width: 550px) {
      padding: 10px 10px 0 10px;
    }
  }
  .close {
    width: 23px;
    height: 23px;
    @media (max-width: 550px) {
      width: 17px;
      height: 17px;
    }
  }
  .out_top {
    padding: 20px;
    @media (max-width: 550px) {
      padding: 10px;
    }
  }
  .price_input {
    position: relative;
    z-index: 5;
    input {
      width: 148px;
      height: 44px;
      border-radius: 35px;
      border: none;
      color: #fff;
      font-size: 21px;
      background-color: #323436;
      // text-indent: 10px;
      text-align: center;
      outline: none;
      @media (max-width: 550px) {
        width: 106px;
        height: 32px;

        border-radius: 26px 26px 26px 26px;
        font-size: 16px;
      }
    }
    input::-webkit-input-placeholder {
      @media (max-width: 550px) {
        font-size: 16px;
      }
    }
  }
  .price_btn {
    width: 148px;
    height: 44px;

    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0.25) 100%
    );
    opacity: 1;
    border: 1px solid #25f484;
    @media (max-width: 550px) {
      width: 106px;
      height: 32px;

      font-size: 16px;
    }
  }
  .out_center {
    padding: 20px;
    @media (max-width: 550px) {
      padding: 10px;
    }
    .out_type {
      width: 118px;
      height: 44px;
      background: #323436;
      border-radius: 74px 74px 74px 74px;
      opacity: 1;
      position: relative;
      cursor: pointer;
      @media (max-width: 550px) {
        width: 85px;
        border-radius: 53px;
        font-size: 12px;
        height: 32px;
      }
      .out_type_select {
        position: absolute;
        top: 46px;
        left: 0;
        z-index: 5;
        background: rgba(16, 17, 21, 1);
        width: 100%;
        @media (max-width: 550px) {
          top: 36px;
        }
      }
      .out_type_select_btn {
        width: 100%;
        height: 44px;
        @media (max-width: 550px) {
          height: 30px;
        }
        &:hover {
          transition: all 0.3s ease;

          background: linear-gradient(
            360deg,
            rgba(37, 244, 132, 0.5) 0%,
            rgba(37, 244, 132, 0.25) 100%
          );
          border: 1px solid rgba(37, 244, 132, 1);
        }
      }
      .active {
        transition: all 0.3s ease;

        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        border: 1px solid rgba(37, 244, 132, 1);
      }
    }
    .out_center_right {
      width: 64%;
      height: 42px;
      padding: 0 2% 0 5%;
      background: #323436;
      border-radius: 70px 70px 70px 70px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 550px) {
        width: 64%;
        height: 32px;
      }
      input {
        width: 100%;
        height: 100%;
        border-radius: 35px;
        border: 1px solid #323436;
        color: #fff;
        font-size: 15px;
        background-color: #323436;
        text-indent: 10px;
        outline: none;
        @media (max-width: 550px) {
          font-size: 12px;
        }
      }
    }
  }
  .out_foot {
    background: #101115;
    border-radius: 15px 15px 15px 15px;
    text-align: center;
    height: 665px;
  }
  .out_bottom {
    opacity: 1;
    padding: 10px;
    background: #101115;
    border-radius: 15px 15px 15px 15px;
    height: 665px;
    overflow: hidden;

    overflow-y: scroll;
    text-align: center;
    cursor: pointer;
    @media (max-width: 550px) {
      height: 565px;
    }

    .out_bottom_sc {
    }
    .out_bottom_weapone {
      width: 100%;
      height: 170px;
      background: rgba(50, 52, 54, 0.25);
      border-radius: 14px 14px 14px 14px;
      opacity: 0.8;
      background-size: 100% 100%;
      transition: all 0.3s linear;
      font-size: 12px;
      @media (max-width: 550px) {
        height: 121px;
        font-size: 9px;
      }

      &:hover {
        opacity: 1;
        img {
          transition: all 0.3s linear;
          transform: translateY(-5px);
        }
      }
      &:not(:hover) {
        opacity: 0.8;

        img {
          // transform: translateY(-5px);
          transition: all 0.3s linear;
          transform: translateY(0px);
        }
      }
    }
    .out_bottom_img {
      width: 109px;
      height: 86px;
      margin: 0 auto;
      @media (max-width: 550px) {
        width: 78px;
        height: 61px;
      }
    }
  }
}
.out_bottom::-webkit-scrollbar {
  width: 7px;
}

.out_bottom::-webkit-scrollbar-track {
  display: none;
}
.out_bottom::-webkit-scrollbar-thumb {
  background-color: rgba(50, 52, 54, 1);
  border-radius: 5px;
}
.fall {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  @media (max-width: 550px) {
    width: 100%;
  }
  .fall_name {
    font-size: 31px;

    @media (max-width: 550px) {
      font-size: 22px;
    }
  }
  .fall_weapon {
    width: 95%;
    margin: 0 auto;
    position: relative;
    height: 479px;
    background: #101115;
    border-radius: 23px 23px 23px 23px;
    overflow: hidden;
    justify-content: space-around;
    @media (max-width: 550px) {
      height: 342px;
    }
    div:first-child {
    }
  }
  .fall_weapon::before {
    content: ""; /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 278px;
    background: linear-gradient(
      -219deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 40%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
  .fall_img {
    width: 232px;
    height: 227px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    @media (max-width: 550px) {
      width: 165px;
      height: 177px;
    }
    .img_box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 13px;
      border-radius: 20px;
      background-size: 100% 100%;
      height: 146px;
      width: 200px;
    }
    img {
      width: 158px;
      @media (max-width: 550px) {
        width: 113px;
      }
    }

    // border: 2px solid #dbab55;

    border-radius: 20px 20px 20px 20px;
  }
  .fall_btn {
    width: 95%;
    margin: 0 auto;
    height: 46px;
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0.25) 100%
    );
    opacity: 0.8;
    border: 2px solid rgba(37, 244, 132, 1);
    font-size: 23px;
    &:hover {
      opacity: 1;
      transition: all 0.3s linear;
    }
    @media (max-width: 550px) {
      width: 320px;
      height: 33px;
      font-size: 17px;
    }
  }
}
table {
  border-collapse: separate;
  border-spacing: 0 10px; /* 设置行间距为10像素 */
}
tr {
  border-bottom: 10px solid transparent; /* 添加透明的底部边框来模拟间距 */
}
.table {
  border: transparent;
  text-align: center;
  margin-top: 5%;
  tr {
    th:first-child {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }
    th:last-child {
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }
  .table_header {
    border: transparent;
    height: 70px;
    background: rgba(16, 17, 21, 1);
    color: rgba(114, 116, 119, 1);
    font-weight: bold;

    font-size: 18px;
    @media (max-width: 550px) {
      height: 50px;

      font-size: 13px;
    }
  }
  .table_body {
    margin-top: 4px;
    border: transparent;
    height: 49px;
    background: #101115;
    border-radius: 7px 7px 7px 7px;
    font-size: 11px;

    @media (max-width: 550px) {
      height: 35px;
    }
    font-size: 8px;
    th {
      font-weight: 500;

      color: #d1d1d1;
    }
  }
}
.img {
  width: 49px;
  @media (max-width: 550px) {
    width: 35px;
  }
}
.fail {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.table_box {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  height: 444px;
  overflow-y: auto;
  @media (max-width: 550px) {
    height: 340px;
  }
}
</style>
